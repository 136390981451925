import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFavorite.module.css';

const IconFavorite = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <g clipPath="url(#clip0_1747_9669)">
        <g clipPath="url(#clip1_1747_9669)">
          <path
            fill="#F7FFD6"
            d="M7.899 12.794L2.182 6.907a3.306 3.306 0 01-.634-3.854 3.406 3.406 0 015.418-.868l.933.851.933-.85a3.407 3.407 0 015.418.867 3.307 3.307 0 01-.634 3.854l-5.717 5.887z"
          ></path>
          <path
            fill="#DFFF60"
            d="M7.898 7a15.882 15.882 0 01-6.5-1.319c.168.462.435.88.783 1.226l5.717 5.887 5.717-5.887a3.31 3.31 0 00.782-1.229A15.875 15.875 0 017.897 7z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            d="M7.899 12.076L2.541 6.558h0l-.009-.008a2.806 2.806 0 01.34-4.291 2.906 2.906 0 013.743.282l.007.007.007.007.933.85.337.308.337-.307.933-.851.007-.007.007-.006a2.907 2.907 0 014.622.738 2.806 2.806 0 01-.54 3.27h0l-.008.008-5.358 5.518z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1747_9669">
          <path
            fill="#fff"
            d="M0 0H14V14H0z"
            transform="translate(.898)"
          ></path>
        </clipPath>
        <clipPath id="clip1_1747_9669">
          <path
            fill="#fff"
            d="M0 0H14V14H0z"
            transform="translate(.898)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconFavorite.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconFavorite.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFavorite;
