import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconInsuranceCoverageInfo.module.css';

const IconInsuranceCoverageInfo = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
    className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#F7FFD6"
        d="M23.5 8v10a2.006 2.006 0 01-2 2h-19a2.006 2.006 0 01-2-2V8h23z"
      ></path>
      <path fill="#fff" d="M.5 8v10a2.006 2.006 0 002 2h1.336l12-12H.5z"></path>
      <path
        fill="#fff"
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M23.5 6v2H.5V6a2.006 2.006 0 012-2h19a2.006 2.006 0 012 2z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M23.5 8v10a2.006 2.006 0 01-2 2h-19a2.006 2.006 0 01-2-2V8h23zM21.5 12h-8M16.5 15h-3"
      ></path>
      <path
        fill="#DFFF60"
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.5 13.25a.25.25 0 00-.25-.25H7.5v-1.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V13H3.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H5.5v1.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V15h1.75a.25.25 0 00.25-.25v-1.5z"
      ></path>
    </svg>
  )
};

IconInsuranceCoverageInfo.defaultProps = { className: null };

IconInsuranceCoverageInfo.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconInsuranceCoverageInfo;
