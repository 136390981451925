import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

export const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg
      className={classNames(className, css.rootSvg)}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <rect width="13" height="13" x="0.5" y="0.5" fill="#fff" rx="3.5"></rect>
      <rect
        width="13"
        height="13"
        x="0.5"
        y="0.5"
        stroke="#21262A"
        rx="3.5"
      ></rect>
      <g clipPath="url(#clip0_89_18932)" className={checkedClassName || css.checked}>
        <path fill="#DFFF60" d="M4 7a3 3 0 106 0 3 3 0 00-6 0z"></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.875 4.125L5.75 9.875 4.125 8.25"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_89_18932">
          <path fill="#fff" d="M0 0H6V6H0z" transform="translate(4 4)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    isDropdown,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
      checkedClassName: css.checkedSuccess,
      boxClassName: css.boxSuccess,
    }
    : {};
  const disabledColorMaybe = rest.disabled
    ? {
      checkedClassName: css.checkedDisabled,
      boxClassName: css.boxDisabled,
    }
    : {};

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const { input, disabled } = props;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
              disabled={disabled}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox
            className={svgClassName}
            {...successColorVariantMaybe}
            {...disabledColorMaybe}
          />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
