import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconMinus.module.css';

const IconMinus = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_2100_46766)">
        <path
          fill="#DFFF60"
          d="M.814 10a9.185 9.185 0 1018.371 0 9.185 9.185 0 00-18.37 0z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M10 19.186A9.186 9.186 0 0110 .814v18.372z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M.814 10a9.185 9.185 0 1018.371 0 9.185 9.185 0 00-18.37 0z"
        ></path>
        <path
          fill="#fff"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M5.208 8.802h9.585s.4 0 .4.4v1.597s0 .4-.4.4H5.208s-.4 0-.4-.4V9.2s0-.4.4-.4z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2100_46766">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
};

IconMinus.defaultProps = { className: null };

IconMinus.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMinus;
