import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPDF.module.css';

const IconPDF = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g stroke="#26736F" strokeWidth="3">
        <path
          strokeLinejoin="round"
          d="M26.002 2.404C27.578 3.105 30.789 4.79 34 8c3.21 3.21 4.894 6.422 5.595 7.998-2.434-.025-5.473-.26-7.956-.497a5.694 5.694 0 01-5.14-5.14c-.237-2.483-.473-5.522-.497-7.957z"
        ></path>
        <path strokeLinecap="round" d="M10 21h16"></path>
        <path strokeLinecap="round" d="M10 29h8"></path>
        <path strokeLinecap="round" d="M10 13h10"></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M40 24.5V24c0-2.271-.023-4.337-.062-6.206-.014-.68-.14-1.354-.423-1.974C38.77 14.19 37.095 11.095 34 8c-3.185-3.185-6.37-4.867-7.96-5.578-.605-.271-1.263-.389-1.926-.399C23.125 2.008 22.087 2 21 2c-6.167 0-10.749.264-13.746.52-2.555.22-4.504 2.185-4.707 4.74C2.282 10.6 2 16.03 2 24c0 7.971.282 13.401.547 16.74.203 2.555 2.152 4.52 4.707 4.74 2.997.256 7.58.52 13.746.52h.5"
        ></path>
        <path d="M22.154 37.952a2.907 2.907 0 010-1.904C23.775 31.377 28.464 28 33.996 28c5.533 0 10.222 3.377 11.843 8.048a2.907 2.907 0 010 1.904C44.219 42.623 39.529 46 33.996 46c-5.532 0-10.221-3.377-11.842-8.048z"></path>
        <path
          strokeLinejoin="round"
          d="M38 37a4 4 0 11-8 0 4 4 0 018 0z"
        ></path>
      </g>
    </svg>
  )
};

IconPDF.defaultProps = { className: null };

IconPDF.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPDF;
