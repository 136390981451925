import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconInfoTip.module.css';

const IconInfoTip = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
    className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_1881_1499)">
        <path
          fill="#DFFF60"
          d="M.975 8a7.026 7.026 0 1014.052 0A7.026 7.026 0 00.975 8z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M8 3.21a7.022 7.022 0 016.93 5.908A7.022 7.022 0 10.975 8c.002.375.035.748.097 1.118A7.022 7.022 0 018 3.209z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M.975 8a7.026 7.026 0 1014.052 0A7.026 7.026 0 00.975 8z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.597 11.194h-.958A.64.64 0 018 10.555V6.403a.32.32 0 00-.32-.32h-.957M7.837 4.487a.16.16 0 100 .32.16.16 0 000-.32z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1881_1499">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
};

IconInfoTip.defaultProps = { className: null };

IconInfoTip.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconInfoTip;
