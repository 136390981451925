import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconSessionDynamics.module.css';

const IconSessionDynamics = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (type === 'active-movement') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          clipPath="url(#clip0_272_15347)"
        >
          <path d="M16.79 6.006l2.395-2.396-2.396-2.396M3.21 13.994L.815 16.39l2.397 2.396M19.202 3.61h-1.63M16.006 3.61h-1.629M12.811 3.61h-1.63M9.617 3.61a6.438 6.438 0 00-1.62.102M6.633 4.46c-.399.37-.697.838-.865 1.356M5.656 7.364c.096.538.328 1.041.674 1.463M7.578 9.76c.508.198 1.054.28 1.598.24M10.744 10c.543-.042 1.09.03 1.603.213M13.617 11.108c.357.414.602.912.712 1.448M14.261 14.107a3.2 3.2 0 01-.835 1.378M12.083 16.266c-.53.12-1.076.163-1.618.124M8.899 16.39h-1.63M5.704 16.39h-1.63M2.508 16.39H.878"></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15347">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'no-physical-contact') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#F7FFD6"
          d="M13.05 4.608V4.21a1.198 1.198 0 10-2.397 0V2.612a1.198 1.198 0 10-2.396 0v1.597a1.198 1.198 0 10-2.396 0v7.988L3.739 9.72a.959.959 0 00-1.407 1.278s.677 1.413 1.518 2.996l1.75 3.004a4.003 4.003 0 003.187 1.585h2.663a3.994 3.994 0 003.994-3.993V6.602L13.05 4.608z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M3.85 13.994c-.84-1.583-1.517-2.995-1.517-2.995A.959.959 0 013.739 9.72l2.122 2.477V4.209a1.198 1.198 0 012.396 0M15.445 6.605v7.988a3.994 3.994 0 01-3.993 3.994H8.788A4.002 4.002 0 015.602 17M8.258 8.602v-5.99a1.198 1.198 0 112.396 0v4.393"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M10.654 4.21a1.198 1.198 0 112.396 0v.398M17.841 2.212L2.266 17.788"
        ></path>
      </svg>
    )
  }

  else if (type === 'laying-down') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_272_15395)">
          <path
            fill="#DFFF60"
            d="M19.186 13.594v3.595a1.198 1.198 0 01-1.198 1.198H2.013a1.198 1.198 0 01-1.199-1.198v-3.595h18.372z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M15.99 4.01l3.196 9.584H.814L4.01 4.01h11.982z"
          ></path>
          <path
            fill="#DFFF60"
            d="M2.945 7.204l-2.13 6.39h18.37l-2.13-6.39H2.945z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M17.772 9.353l-.716-2.149H2.945l-.716 2.149h15.543z"
          ></path>
          <path
            fill="#DFFF60"
            d="M4.01 4.01V2.011A1.198 1.198 0 015.208.814h9.585a1.198 1.198 0 011.198 1.198V4.01H4.01z"
          ></path>
          <path
            fill="#DFFF60"
            d="M14.218 5.081a.4.4 0 01-.38.526H6.166a.4.4 0 01-.38-.526l.62-1.87h6.9a.4.4 0 01.379.273l.533 1.597z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M13.877 4.01h2.114l3.195 9.584H.814L4.01 4.01h2.13M19.186 13.594v3.595a1.198 1.198 0 01-1.198 1.198H2.013a1.198 1.198 0 01-1.199-1.198v-3.595M2.943 7.204h14.112"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M14.218 5.081a.4.4 0 01-.38.526H6.166a.4.4 0 01-.38-.526l.62-1.87h6.9a.4.4 0 01.379.273l.533 1.597z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M4.01 4.01V2.011A1.198 1.198 0 015.208.814h9.585a1.198 1.198 0 011.198 1.198V4.01M3.21 18.387v.799M16.79 18.387v.799"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15395">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }

  else if (type === 'physical-contact') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#F7FFD6"
          d="M14.245 10.2V6.605a1.198 1.198 0 112.397 0v7.988a3.994 3.994 0 01-3.994 3.994H9.984a4 4 0 01-3.373-1.854c-1.376-2.167-3.08-5.735-3.08-5.735A.959.959 0 014.936 9.72l2.121 2.477V4.209a1.198 1.198 0 112.396 0V2.612a1.198 1.198 0 112.397 0v1.597a1.198 1.198 0 112.396 0v5.99h-.002z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M9.453 8.773V4.21a1.198 1.198 0 00-2.396 0v5.28a10.434 10.434 0 012.396-.716zM11.45 8.602c1.812-.02 3.6.422 5.192 1.286V6.605a1.199 1.199 0 00-2.396-.008V4.21a1.198 1.198 0 10-2.397 0V2.612a1.199 1.199 0 00-2.396 0v6.161c.66-.114 1.328-.171 1.997-.17z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M9.453 4.21V2.611a1.198 1.198 0 012.396 0v1.597M11.85 9.002V4.209a1.198 1.198 0 112.396 0v2.396"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M14.245 10.2V6.605a1.198 1.198 0 112.397 0v7.988a3.994 3.994 0 01-3.994 3.994H9.984a3.998 3.998 0 01-3.373-1.854c-1.376-2.167-3.08-5.735-3.08-5.735A.959.959 0 014.936 9.72l2.121 2.477V4.209a1.198 1.198 0 012.396 0v4.793"
        ></path>
      </svg>
    )
  }

  else if (type === 'sitting-down-only') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#DFFF60"
          d="M15.991 12.396a1.198 1.198 0 01-1.198 1.198H5.208a1.198 1.198 0 010-2.396h9.585a1.198 1.198 0 011.198 1.198zM13.993 8.403c-.089.998.196 1.995.8 2.795H5.206c.604-.8.889-1.797.799-2.795 0-1.997-1.597-2.796-1.597-4.394A3.15 3.15 0 017.604.814h4.792a3.15 3.15 0 013.195 3.195c0 1.598-1.598 2.397-1.598 4.394z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M7.604 3.04h4.792a3.102 3.102 0 012.964 1.997c.148-.323.227-.673.231-1.028A3.151 3.151 0 0012.396.814H7.604A3.15 3.15 0 004.409 4.01c.004.355.082.705.23 1.027A3.102 3.102 0 017.604 3.04zM14.793 11.198H5.208a1.198 1.198 0 00-1.198 1.198H15.99a1.198 1.198 0 00-1.198-1.198z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M15.991 12.396a1.198 1.198 0 00-1.198-1.198H5.208a1.198 1.198 0 000 2.396h9.585a1.198 1.198 0 001.198-1.198z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M5.207 13.594a3.055 3.055 0 00-.799 1.997c0 1.198 1.598 3.595 0 3.595M14.793 13.594c.5.547.783 1.257.799 1.997 0 1.198-1.598 3.595 0 3.595M5.207 11.198c.604-.8.889-1.797.799-2.795 0-1.997-1.597-2.796-1.597-4.394A3.15 3.15 0 017.604.814h4.792a3.15 3.15 0 013.195 3.195c0 1.598-1.598 2.397-1.598 4.394-.089.998.196 1.995.8 2.795"
        ></path>
      </svg>
    )
  }

  else if (type === 'brief-intro-conversation-only') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_272_15385)">
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M.814 19.186h18.372"
          ></path>
          <path
            fill="#DFFF60"
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M8.163 5.663a.394.394 0 00.488 0c.68-.53 2.556-2.1 2.556-3.32-.008-1.72-2.295-2.265-2.804-.17-.508-2.095-2.796-1.55-2.796.17 0 1.22 1.872 2.79 2.556 3.32z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M14.393 13.195a5.99 5.99 0 01-11.98 0V8.403a.4.4 0 01.398-.4h11.183a.4.4 0 01.4.4v4.792z"
          ></path>
          <path
            fill="#DFFF60"
            d="M8.403 15.972a5.99 5.99 0 01-5.99-5.991v3.214a5.99 5.99 0 1011.98 0V9.981a5.992 5.992 0 01-5.99 5.99z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M14.393 13.195a5.99 5.99 0 01-11.98 0V8.403a.4.4 0 01.398-.4h11.183a.4.4 0 01.4.4v4.792z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M14.393 8.802h1.997a2.796 2.796 0 110 5.591h-2.117"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15385">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }

  else if (type === 'in-depth-conversation') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_272_15373)">
          <path
            fill="#fff"
            d="M19.187 4.728c0 2.157-2.146 3.91-4.793 3.91a5.769 5.769 0 01-1.677-.246l-2.317.765.64-1.636a3.589 3.589 0 01-1.438-2.796c0-2.157 2.145-3.91 4.792-3.91s4.793 1.75 4.793 3.913z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M18.181 2.332a4.817 4.817 0 01-8.538 2.882 3.694 3.694 0 001.396 2.303l-.64 1.636 2.317-.765c.544.164 1.11.247 1.678.246 2.647 0 4.792-1.75 4.792-3.91a3.459 3.459 0 00-1.005-2.392z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M19.187 4.728c0 2.157-2.146 3.91-4.793 3.91a5.769 5.769 0 01-1.677-.246l-2.317.765.64-1.636a3.589 3.589 0 01-1.438-2.796c0-2.157 2.145-3.91 4.792-3.91s4.793 1.75 4.793 3.913z"
          ></path>
          <path
            fill="#DFFF60"
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M14.74 6.348a.4.4 0 01-.577 0l-1.438-1.5a1.021 1.021 0 111.443-1.443l.282.282.282-.282a1.02 1.02 0 111.443 1.444l-1.436 1.5zM9.368 10.943a5.757 5.757 0 01-2.228.455 5.625 5.625 0 01-4.058-1.725 3.394 3.394 0 016.286 1.27z"
          ></path>
          <path
            fill="#F7FFD6"
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M9.4 11.398a3.395 3.395 0 11-6.789 0 3.326 3.326 0 01.472-1.726 5.624 5.624 0 004.057 1.726 5.755 5.755 0 002.229-.455c.022.15.033.302.032.455z"
          ></path>
          <path
            fill="#DFFF60"
            d="M11.198 19.186a5.815 5.815 0 00-10.384 0"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M11.198 19.186a5.815 5.815 0 00-10.384 0"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15373">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'physical-assessments-included') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#DFFF60"
          d="M12.797 7.204H10a3.195 3.195 0 00-2.556 1.278L6.007 10.4h-.042v.063a3.562 3.562 0 012.575 1.925l1.003 2.006h3.254v-1.578l.758-.272a2.396 2.396 0 001.638-2.273V9.6a2.397 2.397 0 00-2.396-2.396z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M12.797 10.4l-1.62.58a1.137 1.137 0 00.72 2.157l1.658-.594a2.396 2.396 0 001.638-2.273V9.6a2.397 2.397 0 00-2.396-2.396H10a3.195 3.195 0 00-2.556 1.278l-1.48 1.973"
        ></path>
        <path
          fill="#F7FFD6"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M10.398 3.61a1.997 1.997 0 103.994 0 1.997 1.997 0 00-3.994 0zM15.191 14.793a1.997 1.997 0 103.994 0 1.997 1.997 0 00-3.994 0z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M19.186 18.387H.814"
        ></path>
        <path
          fill="#F7FFD6"
          d="M6.863 14.393h-4.85a1.198 1.198 0 100 2.396h6.789"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M4.808 14.393H2.013a1.198 1.198 0 100 2.396h6.789"
        ></path>
        <path
          fill="#F7FFD6"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M12.796 16.79H8.802a1.197 1.197 0 01-1.072-.663l-1.335-2.67a1.19 1.19 0 00-1.072-.661h-3.31a1.198 1.198 0 010-2.397h3.311a3.573 3.573 0 013.215 1.988l1.003 2.006h3.254a1.198 1.198 0 110 2.396z"
        ></path>
      </svg>
    )
  }
  else if (type === 'breathwork') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#F7FFD6"
          d="M2.012 10a7.987 7.987 0 1015.974 0 7.987 7.987 0 00-15.974 0z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M10.799 11.997s1.597 2.396 3.994 2.396a4.393 4.393 0 000-8.786c-3.994 0-5.592 8.786-9.585 8.786a4.393 4.393 0 010-8.786c2.396 0 3.594 2.796 3.594 2.796"
        ></path>
      </svg>
    )
  }
  else if (type === 'natural-remedies-included') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#F7FFD6"
          d="M10 .814a3.954 3.954 0 010 5.592 3.954 3.954 0 010-5.592z"
        ></path>
        <path
          fill="#DFFF60"
          d="M10 3.61a1.64 1.64 0 01-1.111-.49A3.932 3.932 0 0010 6.406a3.932 3.932 0 001.111-3.286c-.294.3-.692.475-1.111.49zM13.994 7.204A3.994 3.994 0 0010 11.198a3.994 3.994 0 003.994-3.994zM4.809 12.396A5.192 5.192 0 0110 17.588a5.192 5.192 0 01-5.192-5.192z"
        ></path>
        <path
          fill="#DFFF60"
          d="M15.192 12.396A5.192 5.192 0 0010 17.588a5.192 5.192 0 005.192-5.192zM6.006 7.204A3.994 3.994 0 0110 11.198a3.994 3.994 0 01-3.994-3.994zM13.994 7.204A3.994 3.994 0 0010 11.198l3.994-3.994z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M4.809 12.396A5.192 5.192 0 0110 17.588l-5.192-5.192z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M15.192 12.396A5.192 5.192 0 0010 17.588l5.192-5.192zM6.006 7.204A3.994 3.994 0 0110 11.198L6.006 7.204z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M13.994 7.204A3.994 3.994 0 0010 11.198a3.994 3.994 0 003.994-3.994zM4.809 12.396A5.192 5.192 0 0110 17.588a5.192 5.192 0 01-5.192-5.192z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M15.192 12.396A5.192 5.192 0 0010 17.588a5.192 5.192 0 005.192-5.192zM6.006 7.204A3.994 3.994 0 0110 11.198a3.994 3.994 0 01-3.994-3.994zM10 .814a3.954 3.954 0 010 5.592 3.954 3.954 0 010-5.592zM10 19.186V6.406"
        ></path>
      </svg>
    )
  }

  else return null

};

IconSessionDynamics.defaultProps = { className: null };

IconSessionDynamics.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconSessionDynamics;
