import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconClientCategories.module.css';

const IconClientCategories = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (type === 'teenagers') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#F7FFD6"
          d="M2.012 10a7.987 7.987 0 1015.974 0 7.987 7.987 0 00-15.974 0z"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M17.828 1.613a.78.78 0 00-1.21.133L15.26 3.884a1.59 1.59 0 01-.218.272L2.172 17.02a.799.799 0 001.133 1.127l.564-.564 4.234-4.234 4.233-4.233 3.711-3.709c.165-.164.312-.347.436-.544l1.453-2.283a.78.78 0 00-.108-.967zM6.227 17.02a1.597 1.597 0 103.195 0 1.597 1.597 0 00-3.195 0zM14.7 8.548a1.597 1.597 0 103.194 0 1.597 1.597 0 00-3.195 0z"
        ></path>
      </svg>
    )
  }
  else if (type === 'children') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#DFFF60"
          d="M6.405 14.801v-.408c0-2.396-4.012-.934-3.57-3.288.352-1.879 4.72-.618 5.938-.472h2.453c1.218-.146 5.586-1.407 5.938.472.442 2.354-3.57.892-3.57 3.288v.408l-1.722 3.253a5.432 5.432 0 01-3.745 0L6.405 14.8zM8.652 1.814a2.596 2.596 0 10-3.22 3.914"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M8.652 1.814a2.596 2.596 0 10-3.22 3.914"
        ></path>
        <path fill="#DFFF60" d="M11.348 1.814a2.595 2.595 0 113.22 3.914"></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M11.348 1.814a2.595 2.595 0 113.22 3.914"
        ></path>
        <path
          fill="#DFFF60"
          d="M5.406 6.206a4.593 4.593 0 109.186 0 4.593 4.593 0 00-9.186 0z"
        ></path>
        <path
          fill="#DFFF60"
          d="M10 3.21a4.593 4.593 0 014.522 3.795 4.593 4.593 0 10-9.046 0A4.594 4.594 0 019.999 3.21z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M5.406 6.206a4.593 4.593 0 109.186 0 4.593 4.593 0 00-9.186 0z"
        ></path>
        <path
          fill="#DFFF60"
          d="M4.01 16.989a2.196 2.196 0 104.393 0 2.196 2.196 0 00-4.393 0z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M6.206 15.99a2.197 2.197 0 012.114 1.598 2.197 2.197 0 10-4.227 0 2.197 2.197 0 012.113-1.597z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M4.01 16.989a2.196 2.196 0 104.393 0 2.196 2.196 0 00-4.393 0z"
        ></path>
        <path
          fill="#DFFF60"
          d="M11.598 16.989a2.197 2.197 0 104.393 0 2.197 2.197 0 00-4.393 0z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M13.794 15.99a2.198 2.198 0 012.114 1.598 2.197 2.197 0 10-4.227 0 2.197 2.197 0 012.113-1.597z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M11.598 16.989a2.197 2.197 0 104.393 0 2.197 2.197 0 00-4.393 0zM11.871 18.054a5.431 5.431 0 01-3.744 0M8.773 10.633c-1.218-.146-5.586-1.407-5.938.472-.442 2.354 3.57.892 3.57 3.288v.408M10 6.406v1.198M8.803 8.403l1.198-.8 1.198.8M8.203 5.208a.2.2 0 01.2.2M8.004 5.407a.2.2 0 01.2-.2M8.204 5.607a.2.2 0 01-.2-.2M8.403 5.407a.2.2 0 01-.2.2M11.797 5.208a.2.2 0 00-.2.2M11.997 5.407a.2.2 0 00-.2-.2M11.797 5.607a.2.2 0 00.2-.2M11.598 5.407a.2.2 0 00.2.2"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M11.227 10.633c1.218-.146 5.586-1.407 5.938.472.442 2.354-3.57.892-3.57 3.288v.408"
        ></path>
      </svg>
    )
  }
  else if (type === 'women') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M4.408 6.406a5.591 5.591 0 1011.183 0 5.591 5.591 0 00-11.183 0zM10 11.997v7.189M7.604 16.79h4.792"
        ></path>
        <path
          fill="#DFFF60"
          d="M5.607 6.406a4.393 4.393 0 108.786 0 4.393 4.393 0 00-8.786 0z"
        ></path>
      </svg>
    )
  }
  else if (type === 'men') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_272_15503)">
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M11.734 8.265l7.451-7.45M.814 12.796a6.39 6.39 0 1012.78 0 6.39 6.39 0 00-12.78 0zM19.185 6.406V.814h-5.591"
          ></path>
          <path
            fill="#DFFF60"
            d="M2.012 12.796a5.192 5.192 0 1010.384 0 5.192 5.192 0 00-10.384 0z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15503">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'couples') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_272_15511)">
          <path
            fill="#F7FFD6"
            d="M17.988 9.6A1.198 1.198 0 0016.79 10.8v3.095l-.012.023a1.108 1.108 0 00-1.762-.934 1.119 1.119 0 00-.304.331l-.912 1.367a3.59 3.59 0 00-.605 1.997v2.508h3.595l1.608-2.01c.51-.638.788-1.43.788-2.247v-4.13A1.199 1.199 0 0017.988 9.6zM2.013 9.6A1.198 1.198 0 013.21 10.8v3.095l.012.023a1.109 1.109 0 01.903-1.127 1.118 1.118 0 011.162.524l.912 1.367a3.59 3.59 0 01.605 2v2.505H3.211l-1.609-2.01a3.594 3.594 0 01-.788-2.247v-4.13A1.198 1.198 0 012.013 9.6zM7.603 12.796h.799l.4-3.595H10V7.204a2.396 2.396 0 00-4.793 0v1.997h1.198l.4 3.595h.798zM12.396 12.796h.799l.4-3.595h1.197V7.204a2.397 2.397 0 00-4.792 0v1.997h1.198l.4 3.595h.798z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M7.993 4.838a2.396 2.396 0 00-2.786 2.366v1.997h1.198l.4 3.595h1.597l.194-1.751M11.717 4.905a2.396 2.396 0 013.075 2.3V9.2h-1.198l-.4 3.595h-1.597l-.214-1.93"
          ></path>
          <path
            fill="#DFFF60"
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M10.288 10.098a.4.4 0 01-.577 0L8.054 8.371A1.151 1.151 0 019.68 6.743l.32.32.319-.32a1.15 1.15 0 111.627 1.628l-1.658 1.727z"
          ></path>
          <path
            fill="#F7FFD6"
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M6.006 2.412a1.598 1.598 0 103.195 0 1.598 1.598 0 00-3.195 0zM10.799 2.412a1.597 1.597 0 103.195 0 1.597 1.597 0 00-3.195 0z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M3.21 19.186l-1.608-2.01a3.594 3.594 0 01-.788-2.247v-4.13a1.198 1.198 0 112.397 0v3.095"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M6.805 19.186V16.68c0-.711-.21-1.406-.604-1.997l-.913-1.366a1.118 1.118 0 00-1.959 1.075l1.08 1.797M16.79 19.186l1.608-2.01c.51-.638.788-1.43.787-2.247v-4.13a1.198 1.198 0 10-2.396 0v3.095"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M13.195 19.186V16.68c0-.711.21-1.406.605-1.997l.913-1.366a1.119 1.119 0 011.96 1.075l-1.081 1.797"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15511">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'golden-age') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_272_15560)">
          <path
            fill="#F7FFD6"
            d="M6.406 19.186l.4-4.793h1.996v-2.796a3.994 3.994 0 00-7.988 0v2.796h1.997l.4 4.793h3.195zM2.012 3.61a2.796 2.796 0 105.591 0 2.796 2.796 0 00-5.591 0z"
          ></path>
          <path
            fill="#fff"
            d="M8.802 11.598v1.597a3.994 3.994 0 00-7.988 0v-1.598a3.993 3.993 0 117.988 0zM7.603 3.61c.001.27-.04.54-.12.799a2.79 2.79 0 00-5.351 0 2.65 2.65 0 01-.12-.799 2.796 2.796 0 115.591 0z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M8.588 10.305a3.994 3.994 0 00-7.774 1.293v2.795h1.997l.4 4.793h3.195l.4-4.793h.798M2.012 3.61a2.796 2.796 0 105.591 0 2.796 2.796 0 00-5.591 0z"
          ></path>
          <path
            fill="#DFFF60"
            d="M14.68 19.063a.398.398 0 01-.576 0l-3.796-3.96a2.417 2.417 0 013.417-3.418l.667.666.667-.667a2.417 2.417 0 113.418 3.418l-3.796 3.96z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M18.93 12.316a2.415 2.415 0 00-3.87-.628l-.668.667-.666-.667a2.413 2.413 0 00-3.971 2.545 5.784 5.784 0 004.637 2.022 5.782 5.782 0 004.637-2.022 2.414 2.414 0 00-.099-1.917z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M14.68 19.063a.398.398 0 01-.576 0l-3.796-3.96a2.417 2.417 0 013.417-3.418l.667.666.667-.667a2.417 2.417 0 113.418 3.418l-3.796 3.96z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15560">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'LGBTQ+') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M5.607 8.802a4.393 4.393 0 108.787 0 4.393 4.393 0 00-8.787 0z"
        ></path>
        <path
          fill="#DFFF60"
          d="M6.805 8.802a3.195 3.195 0 106.39 0 3.195 3.195 0 00-6.39 0z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M10 13.195v5.591M7.604 16.39h4.792M14.793 1.214h2.796v2.795M2.412 4.01V1.213h2.796M17.588 1.214l-4.393 4.393M2.412 1.214l4.393 4.393M6.605 3.011L4.408 5.208"
        ></path>
      </svg>
    )
  }
  else if (type === 'same-sex-couples') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#fff"
          d="M6.89 9.281C8.273 10.573 10 11.438 10 13.594a2.796 2.796 0 01-2.796 2.796H5.208a4.393 4.393 0 01-4.394-4.393v-1.198c1.199.798 1.997-.4 3.595-.4a2.995 2.995 0 012.396 1.198s1.957.026.086-2.316z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M7.204 14.67H5.208A4.394 4.394 0 01.85 10.82c-.012-.008-.024-.013-.037-.021v1.198a4.393 4.393 0 004.394 4.393h1.996A2.796 2.796 0 0010 13.594a3.42 3.42 0 00-.13-.916 2.787 2.787 0 01-2.666 1.992z"
        ></path>
        <path
          fill="#fff"
          d="M8.003 7.73c-.815-.046-2.819-.43-2.796-2.123a1.997 1.997 0 113.994 0 1.409 1.409 0 00.4 1.597 2.894 2.894 0 01-1.598.525z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M6.89 9.281C8.273 10.573 10 11.438 10 13.594a2.796 2.796 0 01-2.796 2.796H5.208a4.393 4.393 0 01-4.394-4.393v-1.198c1.199.798 1.997-.4 3.595-.4a2.995 2.995 0 012.396 1.198M5.208 5.607A4.706 4.706 0 006.89 9.28"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M5.207 5.607a1.997 1.997 0 013.994 0 1.409 1.409 0 00.4 1.597 2.894 2.894 0 01-1.598.525"
        ></path>
        <path fill="#DFFF60" d="M6.805 6.006A1.87 1.87 0 008.003 7.73"></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M6.805 6.006A1.87 1.87 0 008.003 7.73"
        ></path>
        <path
          fill="#fff"
          d="M11.996 7.73c.815-.046 2.819-.43 2.796-2.123a1.997 1.997 0 00-3.994 0 1.41 1.41 0 01-.4 1.597c.47.33 1.025.512 1.598.525zM13.11 9.281C11.729 10.573 10 11.438 10 13.594a2.796 2.796 0 002.796 2.796h1.997a4.395 4.395 0 004.393-4.393v-1.198c-1.198.798-1.997-.4-3.595-.4a2.995 2.995 0 00-2.396 1.198s-1.957.026-.085-2.316z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M14.793 14.67h-1.997a2.787 2.787 0 01-2.665-1.996c-.085.3-.13.609-.131.92a2.796 2.796 0 002.796 2.796h1.997a4.395 4.395 0 004.393-4.393v-1.198c-.013.008-.025.013-.037.021a4.393 4.393 0 01-4.357 3.85z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M13.11 9.281C11.729 10.573 10 11.438 10 13.594a2.796 2.796 0 002.796 2.796h1.997a4.395 4.395 0 004.393-4.393v-1.198c-1.198.798-1.997-.4-3.595-.4a2.995 2.995 0 00-2.396 1.198M14.792 5.607A4.706 4.706 0 0113.11 9.28M14.792 5.607a1.997 1.997 0 00-3.994 0 1.41 1.41 0 01-.4 1.597c.47.33 1.025.512 1.598.525"
        ></path>
        <path
          fill="#DFFF60"
          d="M13.194 6.006a1.869 1.869 0 01-1.198 1.723"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.83"
          d="M13.194 6.006a1.869 1.869 0 01-1.198 1.723"
        ></path>
      </svg>
    )
  }
  else if (type === 'individuals-with-disabilities') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_272_15573)">
          <path
            fill="#DFFF60"
            d="M10.576 10a.798.798 0 01-1.153 0l-4.19-4.372A2.819 2.819 0 119.222 1.64l.778.778.778-.778a2.818 2.818 0 113.988 3.987L10.575 10z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M10.778 1.641L10 2.419l-.778-.778a2.812 2.812 0 00-4.744 2.577A11.802 11.802 0 0010 5.527c1.92.019 3.816-.431 5.523-1.31a2.813 2.813 0 00-4.745-2.578v.002z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M10.576 10a.798.798 0 01-1.153 0l-4.19-4.372A2.819 2.819 0 119.222 1.64l.778.778.778-.778a2.818 2.818 0 113.988 3.987L10.575 10z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M17.616 19.186a20.728 20.728 0 001.57-7.899v-3.43a1.052 1.052 0 00-2.093-.142l-.99 7.258a.72.72 0 01-1.407.058l-.52-2.34a.886.886 0 00-1.752.192c0 1.623.166 3.241.494 4.83l.305 1.473"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M17.616 19.186a20.728 20.728 0 001.57-7.899v-3.43a1.052 1.052 0 00-2.093-.142l-.99 7.258a.72.72 0 01-1.407.058l-.52-2.34a.886.886 0 00-1.752.192c0 1.623.166 3.241.494 4.83l.305 1.473"
          ></path>
          <path
            fill="#F7FFD6"
            d="M2.385 19.186a20.727 20.727 0 01-1.57-7.899v-3.43a1.052 1.052 0 012.093-.142l.99 7.258a.719.719 0 001.406.058l.521-2.34a.886.886 0 011.752.192c0 1.623-.166 3.241-.495 4.83l-.304 1.473"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M2.385 19.186a20.727 20.727 0 01-1.57-7.899v-3.43a1.052 1.052 0 012.093-.142l.99 7.258a.719.719 0 001.406.058l.521-2.34a.886.886 0 011.752.192c0 1.623-.166 3.241-.495 4.83l-.304 1.473"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15573">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'all-clients') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_272_15582)">
          <path
            fill="#F7FFD6"
            d="M14.193 7.005a2.196 2.196 0 104.393 0 2.196 2.196 0 00-4.393 0zM19.185 12.796a2.796 2.796 0 10-5.591 0v2.396h1.264l.333 3.994h2.396l.334-3.994h1.264v-2.396z"
          ></path>
          <path
            fill="#fff"
            d="M16.39 6.594a2.195 2.195 0 012.003 1.304 2.198 2.198 0 10-4.006 0 2.195 2.195 0 012.003-1.304zM16.39 10a2.795 2.795 0 00-2.796 2.796v1.786a2.796 2.796 0 115.591 0v-1.786A2.796 2.796 0 0016.389 10z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M14.193 7.005a2.196 2.196 0 104.393 0 2.196 2.196 0 00-4.393 0zM19.185 12.796a2.796 2.796 0 10-5.591 0v2.396h1.264l.333 3.994h2.396l.334-3.994h1.264v-2.396z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M8.203 2.612a1.797 1.797 0 103.595 0 1.797 1.797 0 00-3.595 0zM10 5.208a2.396 2.396 0 00-2.396 2.396V9.6h1.198l.399 3.594h1.598l.399-3.594h1.198V7.604A2.397 2.397 0 0010 5.208z"
          ></path>
          <path
            fill="#fff"
            d="M10 2.6a1.788 1.788 0 011.55.905 1.776 1.776 0 00-.279-2.164 1.797 1.797 0 00-3.068 1.27c.002.315.087.623.247.894A1.788 1.788 0 0110 2.6zM10 5.208a2.396 2.396 0 00-2.396 2.396V9.39a2.396 2.396 0 014.792 0V7.604A2.397 2.397 0 0010 5.208z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M8.203 2.612a1.797 1.797 0 103.595 0 1.797 1.797 0 00-3.595 0zM10 5.208a2.396 2.396 0 00-2.396 2.396V9.6h1.198l.399 3.594h1.598l.399-3.594h1.198V7.604A2.397 2.397 0 0010 5.208z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M1.414 7.005a2.197 2.197 0 104.393 0 2.197 2.197 0 00-4.393 0zM6.406 12.796a2.796 2.796 0 00-5.592 0v2.396H2.08l.333 3.994h2.396l.333-3.994h1.265v-2.396z"
          ></path>
          <path
            fill="#fff"
            d="M3.61 6.594a2.195 2.195 0 012.004 1.304 2.195 2.195 0 10-4.2-.893c0 .308.067.612.193.893a2.195 2.195 0 012.004-1.304zM3.61 10a2.796 2.796 0 00-2.796 2.796v1.786a2.796 2.796 0 015.592 0v-1.786A2.796 2.796 0 003.61 10z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.83"
            d="M1.414 7.005a2.197 2.197 0 104.393 0 2.197 2.197 0 00-4.393 0zM6.406 12.796a2.796 2.796 0 00-5.592 0v2.396H2.08l.333 3.994h2.396l.333-3.994h1.265v-2.396z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_272_15582">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }


  else return null
};

IconClientCategories.defaultProps = { className: null };

IconClientCategories.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClientCategories;
