import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import css from './FieldSelectCustom.module.css';

const FieldSelectCustomComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    placeholder,
    icon,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  const hasError = touched && invalid && error;

  const selectClasses = classNames({
    [selectClassName]: selectClassName,
    [css.selectError]: hasError,
  });

  const [isHovered, setIsHovered] = useState(false);

  const handleChange = value => {
    input.onChange(value);
    if (onChange) {
      onChange(value);
    }
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div className={css.dropdownButton} style={isHovered ? { border: "1px solid #b8bfd1", transition: "transition: all ease-in-out 100ms" } : null}>
        <span>
          {input.value.label || placeholder}
        </span>
        {icon && <img className={!isHovered ? css.arrow : css.arrowRotated} src={icon} alt="icon" />}
      </div>
      {isHovered &&
        <div
          className={css.dropdownContent}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              onClick: () => handleChange(child.props.value)
            })
          )}
        </div>
      }
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectCustomComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

const { string, object, node } = PropTypes;

FieldSelectCustomComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,
  id: string,
  label: string,
  input: object.isRequired,
  meta: object.isRequired,
  children: node,
};

const FieldSelectCustom = props => {
  return <Field component={FieldSelectCustomComponent} {...props} />;
};

export default FieldSelectCustom;
