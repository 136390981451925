import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconOfferLocation.module.css';

const IconOfferLocation = props => {
  const { rootClassName, className, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (type === 'in-person') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
      >
        <g clipPath="url(#clip0_1343_49214)">
          <path
            fill="#000"
            fillRule="evenodd"
            d="M3.25 3.643a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zM6 7.179a5.502 5.502 0 00-5.078 3.384.393.393 0 00.362.544h9.432a.393.393 0 00.362-.544A5.502 5.502 0 006 7.18z"
            clipRule="evenodd"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_1343_49214">
            <path fill="#fff" d="M0 0H12V12H0z"></path>
          </clipPath>
        </defs>
      </svg>
    );
  } else if (type === 'online') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M1.679 7.571V2.464h8.642v5.107H1.68zM8.335 8.75h2.183c.542 0 .982-.44.982-.982v-5.5a.982.982 0 00-.982-.982H1.482a.982.982 0 00-.982.982v5.5c0 .542.44.982.982.982h2.183a2.902 2.902 0 00-.534 1.286.59.59 0 001.166.178 1.723 1.723 0 013.406 0 .59.59 0 101.166-.178 2.902 2.902 0 00-.534-1.286z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  } else if (type === 'client-location') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
      >
        <path
          fill="#21262A"
          d="M6 8.97a.655.655 0 00.66-.678V6.33a.115.115 0 01.083-.11 2.64 2.64 0 10-1.485 0 .115.115 0 01.082.11v1.962A.655.655 0 006 8.97zm-.458-5.958a.66.66 0 11-.186-.444c.12.124.187.29.186.462v-.018zM1.16 10.95h9.68a.66.66 0 100-1.32H1.16a.66.66 0 100 1.32z"
        ></path>
      </svg>
    );
  } else return null

};

IconOfferLocation.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconOfferLocation.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconOfferLocation;
