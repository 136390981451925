import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckMark.module.css';

const SIZE_SMALL = 'small';
const SIZE_BIG = 'big';
const SIZE_BIG_BORDER = 'bigBorder';
const SIZE_BIG_CIRCLE = 'bigCircle';

const IconCheckmark = props => {
  const { rootClassName, className, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_BIG_BORDER) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        fill="none"
        viewBox="0 0 19 18"
      >
        <rect width="17" height="17" x="1" y="0.5" fill="#fff" rx="3.5"></rect>
        <rect
          width="17"
          height="17"
          x="1"
          y="0.5"
          stroke="#21262A"
          rx="3.5"
        ></rect>
        <g clipPath="url(#clip0_556_24663)">
          <path
            fill="#DFFF60"
            d="M4.5 8.5a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.313 4.187l-6.188 8.625-2.438-2.438"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_556_24663">
            <path fill="#fff" d="M0 0H9V9H0z" transform="translate(4.5 4)"></path>
          </clipPath>
        </defs>
      </svg>
    );
  }
  else if (size === SIZE_BIG_CIRCLE) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <g clipPath="url(#clip0_2110_15962)">
          <path
            fill="#DFFF60"
            d="M.505 12a11.495 11.495 0 1022.99 0 11.495 11.495 0 00-22.99 0z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.01"
            d="M23.017.983L7.21 23.015.984 16.79"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_2110_15962">
            <path fill="#fff" d="M0 0H24V24H0z"></path>
          </clipPath>
        </defs>
      </svg>
    );
  } else if (size === SIZE_SMALL) {
    return (
      <svg className={classes} width="16" height="12" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 2l-8 8-4-4"
          strokeWidth="2.5"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (size === SIZE_BIG) {
    return (
      <svg className={classes} strokeWidth="2" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.6 1.2c-.4-.3-1-.2-1.3.2L7.8 19l-5.2-5c-.4-.4-1-.4-1.3 0-.4.3-.4.8 0 1l6 5.6.6.2s.2 0 .4-.4l14.3-18c.3-.5.2-1-.2-1" />
      </svg>
    );
  }
};

IconCheckmark.defaultProps = {
  rootClassName: null,
  className: null,
  size: 'big',
};

const { string } = PropTypes;

IconCheckmark.propTypes = {
  rootClassName: string,
  className: string,
  size: string,
};

export default IconCheckmark;
