import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconMapPin.module.css';

const IconMapPin = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#06F"
        fillRule="evenodd"
        d="M7.75 10a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0zm4.75-3.25a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#06F"
        fillRule="evenodd"
        d="M4.022 8.857a8.288 8.288 0 018.26-7.607h.432a8.288 8.288 0 018.26 7.607 8.944 8.944 0 01-1.99 6.396l-4.793 5.861a2.186 2.186 0 01-3.386 0l-4.793-5.861a8.943 8.943 0 01-1.99-6.396zm8.26-6.107a6.788 6.788 0 00-6.764 6.23 7.443 7.443 0 001.656 5.323l4.793 5.862a.687.687 0 001.064 0l4.793-5.862a7.443 7.443 0 001.654-5.323 6.787 6.787 0 00-6.765-6.23h-.43z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

IconMapPin.defaultProps = { className: null };

IconMapPin.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMapPin;
