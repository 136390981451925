import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconVoucher.module.css';

const IconVoucher = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#fff"
        d="M21.603 12a1.917 1.917 0 011.533-1.878.479.479 0 00.383-.47v-3.88a.958.958 0 00-.958-.958H2.441a.958.958 0 00-.959.958v3.88a.48.48 0 00.384.47 1.916 1.916 0 010 3.756.479.479 0 00-.384.47v3.88a.958.958 0 00.959.958h20.12a.958.958 0 00.958-.958v-3.88a.479.479 0 00-.383-.47A1.916 1.916 0 0121.603 12z"
      ></path>
      <path
        fill="#fff"
        d="M21.603 12a1.917 1.917 0 011.533-1.878.479.479 0 00.383-.47v-3.88a.958.958 0 00-.958-.958H2.441a.958.958 0 00-.959.958v3.88a.48.48 0 00.384.47A1.916 1.916 0 013.399 12h18.204z"
      ></path>
      <path
        fill="#fff"
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.67"
        d="M10.585 8.646h8.623s.479 0 .479.48v.958s0 .479-.48.479h-8.623s-.479 0-.479-.48v-.957s0-.48.48-.48z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.67"
        d="M12.021 14.395h2.875M10.105 16.311h4.791"
      ></path>
      <path
        fill="#fff"
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.67"
        d="M16.813 15.353a1.437 1.437 0 102.874 0 1.437 1.437 0 00-2.875 0z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.67"
        d="M21.603 12a1.917 1.917 0 011.533-1.878.479.479 0 00.383-.47v-3.88a.958.958 0 00-.958-.958H2.441a.958.958 0 00-.959.958v3.88a.48.48 0 00.384.47 1.916 1.916 0 010 3.756.479.479 0 00-.384.47v3.88a.958.958 0 00.959.958h20.12a.958.958 0 00.958-.958v-3.88a.479.479 0 00-.383-.47A1.916 1.916 0 0121.603 12z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.67"
        d="M6.272 16.551a.24.24 0 11-.479 0 .24.24 0 01.479 0zM6.272 14.156a.24.24 0 11-.48 0 .24.24 0 01.48 0zM6.272 11.76a.24.24 0 11-.48 0 .24.24 0 01.48 0zM6.272 9.365a.24.24 0 11-.479 0 .24.24 0 01.479 0zM6.272 6.97a.24.24 0 11-.479 0 .24.24 0 01.479 0z"
      ></path>
    </svg>
  )
};

IconVoucher.defaultProps = { className: null };

IconVoucher.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconVoucher;
