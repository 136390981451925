import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconDuration.module.css';

const IconDuration = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <g clipPath="url(#clip0_1255_20424)">
        <path
          fill="#F7FFD6"
          d="M3.303.565h8.393s2.239 0 2.239 2.239v8.393s0 2.239-2.239 2.239H3.303s-2.239 0-2.239-2.239V2.804s0-2.239 2.239-2.239z"
        ></path>
        <path
          fill="#fff"
          d="M7.5.565H3.303a2.245 2.245 0 00-2.239 2.238v8.394a2.245 2.245 0 002.239 2.238H7.5V.565z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.57"
          d="M3.303.565h8.393s2.239 0 2.239 2.239v8.393s0 2.239-2.239 2.239H3.303s-2.239 0-2.239-2.239V2.804s0-2.239 2.239-2.239z"
        ></path>
        <path
          fill="#DFFF60"
          d="M2.744 7a4.756 4.756 0 109.513 0 4.756 4.756 0 00-9.513 0z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M4.136 10.364a4.757 4.757 0 116.727-6.727l-6.727 6.727z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.57"
          d="M2.744 7a4.756 4.756 0 109.513 0 4.756 4.756 0 00-9.513 0z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.57"
          d="M9.46 7H7.502V3.924"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1255_20424">
          <path fill="#fff" d="M0 0H14V14H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  )
};

IconDuration.defaultProps = { className: null };

IconDuration.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDuration;
