import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ComingSoonBadge.module.css';

export const IconGlasses = () => {

  return (
    <svg
      className={css.comingSoonIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#F7FFD6"
        d="M29.1 15.498a5.113 5.113 0 11-10.084 1.687l-1.178-14.11a.64.64 0 01.532-.682l6.474-1.08a.615.615 0 01.715.473l3.54 13.712z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M27.31 20.29a5.111 5.111 0 01-8.294-3.105l-.639-7.68 8.792-1.47 1.93 7.463a5.111 5.111 0 01-1.79 4.792z"
      ></path>
      <path
        fill="#DFFF60"
        d="M29.098 15.498l-.44-1.699a5.112 5.112 0 01-9.803 1.47l.16 1.917a5.113 5.113 0 1010.083-1.687v-.001z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M17.89 11.792l-2.402 7.322a5.115 5.115 0 01-9.405-4.025h.012l3.617-6.786 8.178 3.489z"
      ></path>
      <path
        fill="#DFFF60"
        d="M13.188 17.994a6.594 6.594 0 01-6.242-4.506l-.85 1.6h-.013a5.116 5.116 0 009.405 4.026l.594-1.812c-.899.45-1.89.686-2.894.692z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33"
        d="M17.89 11.792l-2.402 7.322a5.115 5.115 0 01-9.405-4.025h.012l3.617-6.786 8.178 3.489zM8.783 21.8L5.52 29.438M2.582 28.189l5.878 2.507M17.916 11.805l-.025-.013M9.713 8.303l-.025-.013"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33"
        d="M11.4 12.19a.32.32 0 10-.25.587.32.32 0 00.25-.588zM13.316 16.023a.32.32 0 10-.25.589.32.32 0 00.25-.589zM22.414 12.268a.32.32 0 10.59.251.32.32 0 00-.59-.25zM23.053 17.379a.32.32 0 10.587.252.32.32 0 00-.587-.252zM24.971 14.823a.32.32 0 10.587.253.32.32 0 00-.587-.253zM9.482 17.3a.32.32 0 10-.25.589.32.32 0 00.25-.588z"
      ></path>
      <path
        fill="#F7FFD6"
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33"
        d="M19.91 5.658l-2.018 6.134-8.18-3.489 3.055-5.712a.639.639 0 01.805-.281l5.98 2.556a.639.639 0 01.359.792z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33"
        d="M24.898 21.377l1.37 8.193M29.418 29.045l-6.303 1.053M27.204 8.036l-5.483.916M19.014 17.182a5.112 5.112 0 1010.084-1.684L25.562 1.78a.639.639 0 00-.725-.47l-4.991.834"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33"
        d="M19.014 17.182a5.112 5.112 0 1010.084-1.684L25.562 1.78a.639.639 0 00-.725-.47l-4.991.834"
      ></path>
    </svg>
  );
};

const ComingSoonBadge = () => {

  return (
    <div className={css.comingSoon}>
      <IconGlasses />
      <h3 className={css.comingSoonTitle}>
        <FormattedMessage id="BookingTimeForm.comingSoonTitle" />
      </h3>
      <p className={css.comingSoonDescription}>
        <FormattedMessage id="BookingTimeForm.comingSoonDescription" />
      </p>
    </div>
  );
};

ComingSoonBadge.defaultProps = { className: null };

ComingSoonBadge.propTypes = {
  className: string,
  rootClassName: string,
};

export default ComingSoonBadge;
