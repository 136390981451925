import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialShare.module.css';

const IconSocialShare = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <g clipPath="url(#clip0_1747_9657)">
        <path
          fill="#21262A"
          d="M9.453 2.625a2.334 2.334 0 104.667 0 2.334 2.334 0 00-4.667 0zM.703 7A2.333 2.333 0 105.37 7 2.333 2.333 0 00.703 7zM9.453 11.375a2.334 2.334 0 104.667 0 2.334 2.334 0 00-4.667 0z"
        ></path>
        <path
          fill="#21262A"
          d="M11.787 1.458a2.333 2.333 0 012.26 1.75 2.334 2.334 0 10-4.52 0 2.333 2.333 0 012.26-1.75zM3.036 5.833a2.333 2.333 0 012.26 1.75 2.333 2.333 0 10-4.52 0 2.333 2.333 0 012.26-1.75zM11.787 10.208a2.333 2.333 0 012.26 1.75 2.333 2.333 0 10-4.52 0 2.333 2.333 0 012.26-1.75z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.811"
          d="M5.121 8.042l4.58 2.29M9.7 3.667l-4.579 2.29M9.453 2.625a2.334 2.334 0 104.667 0 2.334 2.334 0 00-4.667 0zM.703 7A2.333 2.333 0 105.37 7 2.333 2.333 0 00.703 7zM9.453 11.375a2.334 2.334 0 104.667 0 2.334 2.334 0 00-4.667 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1747_9657">
          <path fill="#fff" d="M0 0H14V14H0z" transform="translate(.41)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconSocialShare.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSocialShare.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSocialShare;
