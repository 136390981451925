import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCategories.module.css';

const IconCategories = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (type === 'empowerment') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#DFFF60"
          d="M3.545 8.683a4.495 4.495 0 108.99 0 4.495 4.495 0 00-8.99 0z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M8.04 6.454a4.495 4.495 0 014.344 3.358A4.496 4.496 0 004.477 5.92a4.495 4.495 0 00-.78 3.893A4.495 4.495 0 018.04 6.454z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.472 11.89L8.04 9.32l2.568 2.568M8.04 9.321V15.1M8.04 2.9V1.616M12.126 4.593l.908-.908M3.954 4.593l-.908-.908M12.126 12.765l.908.908M3.954 12.765l-.908.908M2.261 8.679H.977M15.103 8.679h-1.284M.977 16.384h14.126M11.915 10.949a4.495 4.495 0 10-7.75 0"
        ></path>
      </svg>
    )
  }
  else if (type === 'emotional-support') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g clipPath="url(#clip0_334_23767)">
          <path
            fill="#DFFF60"
            d="M8 15.346a.311.311 0 01-.183-.06c-.726-.521-3.417-2.537-4.638-4.651-1.439-3.01-.145-4.864 1.984-5.139A2.781 2.781 0 018 7.43a2.781 2.781 0 012.837-1.934c2.13.275 3.423 2.13 1.983 5.139-1.22 2.114-3.911 4.13-4.637 4.65a.311.311 0 01-.183.06z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M10.837 5.496A2.781 2.781 0 008 7.43a2.781 2.781 0 00-2.837-1.934c-1.894.245-3.121 1.74-2.358 4.185A9.016 9.016 0 008 11.283a9.016 9.016 0 005.195-1.602c.763-2.444-.464-3.94-2.358-4.185z"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M10.837 5.496A2.781 2.781 0 008 7.43a2.781 2.781 0 00-2.837-1.934"
          ></path>
          <path
            fill="#DFFF60"
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M2.251.654h3.194s.32 0 .32.32v.639s0 .319-.32.319H2.251s-.32 0-.32-.32V.975s0-.32.32-.32zM13.749 1.932h-3.194s-.32 0-.32-.32V.975s0-.32.32-.32h3.194s.32 0 .32.32v.639s0 .319-.32.319z"
          ></path>
          <path
            fill="#F7FFD6"
            d="M3.529 4.647l-.864 1.437a.894.894 0 001.566.86l.73-1.094a2.87 2.87 0 00.484-1.596V1.932H2.826L1.284 3.859a2.874 2.874 0 00-.63 1.796v3.303a.958.958 0 001.917 0V6.483"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M3.529 4.647l-.864 1.437a.894.894 0 001.566.86l.73-1.094a2.87 2.87 0 00.484-1.596V1.932H2.826L1.284 3.859a2.874 2.874 0 00-.63 1.796v3.303a.958.958 0 001.917 0V6.483"
          ></path>
          <path
            fill="#F7FFD6"
            d="M12.471 4.647l.864 1.437a.894.894 0 01-1.567.86l-.73-1.094a2.87 2.87 0 01-.483-1.596V1.932h2.619l1.542 1.927c.408.51.63 1.143.63 1.796v3.303a.958.958 0 01-1.917 0V6.483"
          ></path>
          <path
            stroke="#21262A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.67"
            d="M12.471 4.647l.864 1.437a.894.894 0 01-1.567.86l-.73-1.094a2.87 2.87 0 01-.483-1.596V1.932h2.619l1.542 1.927c.408.51.63 1.143.63 1.796v3.303a.958.958 0 01-1.917 0V6.483M12.82 10.635c-1.22 2.114-3.911 4.13-4.637 4.65a.31.31 0 01-.366 0c-.726-.52-3.417-2.536-4.638-4.65"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_334_23767">
            <path fill="#fff" d="M0 0H16V16H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (type === 'biohacking') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#DFFF60"
          d="M.644 8.032a6.42 6.42 0 1012.841 0 6.42 6.42 0 00-12.841 0z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M7.065 1.612a6.42 6.42 0 00-4.5 10.997l9.076-9.077a6.397 6.397 0 00-4.576-1.92z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M.644 8.032a6.42 6.42 0 1012.841 0 6.42 6.42 0 00-12.841 0zM11.612 12.565l3.824 3.824"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.306 6.332c-.695.82-1.062 1.894.595 2.617.758 1.693 1.912 1.067 2.679.162.693-.817 1-2.869.555-4.2a.321.321 0 00-.482-.165c-1.184.787-2.55.647-3.347 1.586z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M4.842 10.93a11.648 11.648 0 013.032-4.507"
        ></path>
      </svg>
    )
  }
  else if (type === 'stress-management') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M13.498 11.95l-2.77.878c.121-.38-.025-.919-.44-.919H8.04c-.321 0-1.379-1.284-2.89-1.284H3.225v3.572c6.742 2.247 4.495 2.247 12.2-1.605a1.77 1.77 0 00-1.926-.642z"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M.977 9.983h1.926s.321 0 .321.321V14.8s0 .32-.32.32H.976s-.321 0-.321-.32v-4.495s0-.321.321-.321z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M3.224 14.197c6.742 2.247 4.495 2.247 12.2-1.605a1.77 1.77 0 00-1.926-.642l-2.771.878"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M3.224 10.625h1.927c1.51 0 2.568 1.284 2.889 1.284h2.247c.642 0 .642 1.284 0 1.284H6.756"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M14.161 4.86a1.46 1.46 0 00-2.123-.813 1.462 1.462 0 10-2.859 0 1.461 1.461 0 10-.882 2.718 1.461 1.461 0 102.311 1.679 1.463 1.463 0 002.622.106 1.46 1.46 0 00-.31-1.785 1.462 1.462 0 001.241-1.906z"
        ></path>
        <path
          fill="#DFFF60"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M9.471 6.014a1.137 1.137 0 102.274 0 1.137 1.137 0 00-2.274 0z"
        ></path>
      </svg>
    )
  }
  else if (type === 'mindfulness') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M1.62 12.847c0 .81.642 1.588 1.786 2.16 1.144.574 2.695.895 4.313.895 1.618 0 3.17-.321 4.313-.894 1.144-.573 1.787-1.35 1.787-2.16s-.643-1.588-1.787-2.16c-1.144-.574-2.695-.896-4.313-.896-1.618 0-3.17.322-4.313.895-1.144.573-1.787 1.35-1.787 2.16z"
        ></path>
        <path
          fill="#DFFF60"
          d="M4.24 10.338c.84 1.304 3.167 2.243 5.908 2.243 1.173.01 2.339-.177 3.45-.553-.716-1.29-3.076-2.236-5.878-2.236a10.727 10.727 0 00-3.48.546z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M4.188 8.678c0 .724.54 1.418 1.504 1.93.963.512 2.27.8 3.632.8 1.363 0 2.669-.288 3.632-.8.964-.512 1.505-1.206 1.505-1.93s-.541-1.419-1.505-1.93c-.963-.513-2.27-.8-3.632-.8s-2.669.287-3.632.8c-.963.511-1.505 1.206-1.505 1.93z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
        <path
          fill="#DFFF60"
          d="M10.073 7.903a7.63 7.63 0 003.468-.782 7.184 7.184 0 00-4.217-1.173 8.09 8.09 0 00-3.457.711 7.058 7.058 0 004.206 1.244z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
        <path
          fill="#fff"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
        <path
          fill="#F7FFD6"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
        <path
          fill="#fff"
          d="M5.472 4.505c0 .137.018.272.054.404a5.367 5.367 0 002.514.583c2.127 0 3.853-1.077 3.853-2.408 0-.136-.019-.272-.054-.403a5.358 5.358 0 00-2.515-.583c-2.127 0-3.852 1.078-3.852 2.407z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M13.218 6.9a2.215 2.215 0 011.243 1.779c0 1.508-2.3 2.729-5.137 2.729-2.837 0-5.136-1.22-5.136-2.729 0-1.01 1.032-1.892 2.568-2.364"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702zM13.542 11.941c.177.272.273.588.277.911 0 1.685-2.731 3.05-6.1 3.05-3.37 0-6.1-1.365-6.1-3.05 0-1.185 1.352-2.213 3.329-2.717"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M5.472 4.505c0 .639.406 1.251 1.128 1.703.723.452 1.702.705 2.724.705 1.022 0 2.002-.253 2.724-.705.723-.452 1.129-1.064 1.129-1.703 0-.638-.406-1.25-1.129-1.702-.722-.452-1.702-.705-2.724-.705-1.022 0-2.001.253-2.724.705-.722.451-1.128 1.064-1.128 1.702z"
        ></path>
      </svg>
    )
  }
  else if (type === 'fitness') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill="#F7FFD6"
          d="M8.04 15.377l-6.292-6.48a3.64 3.64 0 01-.699-4.242A3.75 3.75 0 017.013 3.7l1.027.937L9.067 3.7a3.75 3.75 0 015.963.955 3.64 3.64 0 01-.697 4.242l-6.293 6.48z"
        ></path>
        <path
          fill="#DFFF60"
          d="M10.817 8.402a.321.321 0 00-.293.192L9.61 10.65a.322.322 0 01-.584.005l-1.708-3.66a.321.321 0 00-.558-.042l-.872 1.307a.321.321 0 01-.267.143H1.326c.125.177.266.343.42.495l6.294 6.48 6.292-6.48c.155-.152.296-.318.42-.495h-3.935z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M8.04 15.377l-6.292-6.48a3.64 3.64 0 01-.699-4.242A3.75 3.75 0 017.013 3.7l1.027.937L9.067 3.7a3.75 3.75 0 015.963.955 3.64 3.64 0 01-.697 4.242l-6.293 6.48z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.67"
          d="M1.326 8.402h4.295a.321.321 0 00.267-.143L6.76 6.95a.321.321 0 01.558.043l1.708 3.66a.321.321 0 00.585-.005l.913-2.055a.321.321 0 01.293-.193h3.937"
        ></path>
      </svg>
    )
  }

  else return null
};

IconCategories.defaultProps = { className: null };

IconCategories.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCategories;
