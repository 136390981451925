import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPlus.module.css';

const IconPlus = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#F7FFD6"
        d="M1.612 1.359h12.775s.64 0 .64.638v12.775s0 .64-.64.64H1.612s-.638 0-.638-.64V1.997s0-.638.638-.638z"
      ></path>
      <path
        fill="#DFFF60"
        d="M14.387 15.411H1.612a.639.639 0 01-.638-.638v-1.917a.639.639 0 00.638.639h12.775a.638.638 0 00.64-.639v1.917a.639.639 0 01-.64.638z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.67"
        d="M8 4.552v7.665M11.833 8.385H4.168M1.612 1.359h12.775s.64 0 .64.638v12.775s0 .64-.64.64H1.612s-.638 0-.638-.64V1.997s0-.638.638-.638z"
      ></path>
    </svg>
  )
};

IconPlus.defaultProps = { className: null };

IconPlus.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPlus;
