import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFile.module.css';

const IconFile = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#26736F"
        d="M1.615 16.5c-.46 0-.844-.154-1.153-.462A1.565 1.565 0 010 14.884V2.116c0-.46.154-.845.463-1.154A1.565 1.565 0 011.615.5h12.768c.46 0 .845.154 1.153.463.309.308.463.692.463 1.153v12.768c0 .46-.154.845-.463 1.154a1.565 1.565 0 01-1.153.462H1.616zM1 2.116v12.768c0 .155.064.296.192.424s.27.192.423.192h12.77a.588.588 0 00.423-.192.588.588 0 00.192-.424V2.116a.588.588 0 00-.192-.424.588.588 0 00-.424-.192H12.5v6.115l-2-1.192-2 1.192V1.5H1.615a.588.588 0 00-.423.192.588.588 0 00-.192.423z"
      ></path>
    </svg>
  );
};

IconFile.defaultProps = { className: null };

IconFile.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFile;
