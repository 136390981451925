import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCircleArrow.module.css';

const IconCircleArrow = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_1526_46538)">
        <path
          fill="#DFFF60"
          d="M2.5 12a9.5 9.5 0 1019 0 9.5 9.5 0 00-19 0z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.414 16.5a11.5 11.5 0 100-9M12.5 16l4-4-4-4M16.5 12H.5"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1526_46538">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
};

IconCircleArrow.defaultProps = { className: null };

IconCircleArrow.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCircleArrow;
