import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCarouselArrow.module.css';

const IconCarouselArrow = props => {
  const { className, rootClassName, direction, disabled } = props;
  const classes = classNames(rootClassName || css.root, className, { [css.disabled]: disabled });

  if (direction === "left") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="26"
        fill="none"
        viewBox="0 0 18 26"
      >
        <path
          fill="#F6F9F9"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.184 13.423L14.46 24.921a.533.533 0 00.752-.055l1.66-1.938a.53.53 0 00-.06-.751l-10.53-8.943a.265.265 0 010-.406l10.53-8.943a.53.53 0 00.06-.751l-1.66-1.948a.532.532 0 00-.752-.056L1.184 12.618a.533.533 0 000 .805z"
        ></path>
      </svg>
    )
  } else if (direction === "right") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="26"
        fill="none"
        viewBox="0 0 18 26"
      >
        <path
          fill="#F6F9F9"
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.816 12.628L3.54 1.13a.532.532 0 00-.752.056l-1.66 1.937a.532.532 0 00.06.752l10.53 8.943a.267.267 0 01.07.314.267.267 0 01-.07.091l-10.53 8.943a.531.531 0 00-.06.752l1.66 1.948a.531.531 0 00.752.055l13.276-11.488a.534.534 0 000-.805z"
        ></path>
      </svg>
    )
  }
  else {
    return null
  }
};

IconCarouselArrow.defaultProps = { className: null };

const { string } = PropTypes;

IconCarouselArrow.propTypes = {
  className: string,
  direction: string,
};

export default IconCarouselArrow;
